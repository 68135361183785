import React from 'react';

import Header from './Header';
import Footer from './Footer';
import './App.css';


function Privacy() {
  return (

    <div className="page-container">
      <div className="content-wrap">


    <div>



<Header />
<div style={{ margin: '0 auto', maxWidth: '1000px', paddingLeft: '20px', paddingRight: '20px' }}>
      <h1 style={{ textAlign: 'left' }}>Privacy Policy</h1>
      <p style={{ textAlign: 'left' }}>
      At Networking AI, we take your privacy very seriously and are committed to protecting your personal information. 
      This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website 
      https://www.networkingai.org or any related services.
      </p>

      <h2 style={{ textAlign: 'left' }}>1. Collection of Personal Information</h2>
      <p style={{ textAlign: 'left' }}>
      When you use our website, we may collect certain personal information from you, such as your name, email address, 
      and phone number. We may also collect non-personal information, such as your IP address and browser type.
      </p>

      <h2 style={{ textAlign: 'left' }}>2. Use of Personal Information</h2>
      <p style={{ textAlign: 'left' }}>
      We may use your personal information to provide you with our services, to communicate with you, and to improve our 
      website. We may also use your personal information to send you promotional materials or other communications that 
      we think may be of interest to you.
      </p>

      <h2 style={{ textAlign: 'left' }}>3. Disclosure of Personal Information</h2>
      <p style={{ textAlign: 'left' }}>
      We will not sell, rent, or share your personal information with any third parties without your consent, except as 
      required by law or as necessary to provide our services.

      </p>

      <h2 style={{ textAlign: 'left' }}>4. Security of Personal Information</h2>
      <p style={{ textAlign: 'left' }}>
      We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, 
      no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the 
      security of your personal information.
      </p>

      <h2 style={{ textAlign: 'left' }}>5. Cookies and Tracking Technologies</h2>

      <p style={{ textAlign: 'left' }}>
      We may use cookies and other tracking technologies to collect information about your use of our website and to personalize 
      your experience. You can choose to disable cookies in your browser settings, but this may affect your ability to use certain 
      features of our website.
      </p>

      <h2 style={{ textAlign: 'left' }}>6. Links to Third-Party Websites</h2>
      <p style={{ textAlign: 'left' }}>
      Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these 
      websites, and we encourage you to review their privacy policies before providing any personal information.
      </p>

      <h2 style={{ textAlign: 'left' }}>7. Changes to This Privacy Policy</h2>
      <p style={{ textAlign: 'left' }}>
      We may update this Privacy Policy from time to time, and we encourage you to review it periodically.
      Your continued use of our website after any changes to this Privacy Policy constitutes your acceptance of the updated Privacy Policy.
      </p>

      <h2 style={{ textAlign: 'left' }}>8. Contact Us</h2>
      <p style={{ textAlign: 'left' }}>
      If you have any questions or concerns about this Privacy Policy or our use of your personal information, please contact us.
      </p>

      
      <div className="terms-spacer"></div>


      </div>

      <Footer />

      </div>








      </div>
    </div>
  );
}

export default Privacy;
