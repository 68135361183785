import React from 'react';

function Header() {
  return (
    <header style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={{ maxHeight: '50px', margin: 0 }} />
        </a>
      </div>

      <div style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 0 10px', textDecoration: 'none', color: '#000000' }}>
        <a href="/" style={{ textDecoration: 'none', color: '#000000' }}>Networking AI</a>
      </div>
    </header>
  );
}

export default Header;
