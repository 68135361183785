import { useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import './App.css';


import Header from './Header';
import Footer from './Footer';

import Toolscards from './Toolscards';


function App() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);


  
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAPI_KEY,
    
  });
  const openai = new OpenAIApi(configuration);

  const onChange = (e) => {
    setQuestion(e.target.value);
  };



  const onClick = async () => {
    if (!question) {
      alert("Please enter a job position.");
      return;
    }

    setLoading(true);
    const response = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      
      messages:[
        {"role": "system", "content": "I want you to write a cover letter based on the user input starting with Dear [Recipient's Name],."},
        {"role": "user", "content": question},
    ],
      
      temperature: 0.7,
      max_tokens: 450,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    
    setLoading(false);
    setAnswer(response.data.choices[0].message.content);
  };
  
  
  return (


<div className="page-container">
      <div className="content-wrap">

    

<Header />

      <h1>Cover Letter Generator</h1>
      <p>Enter a job position</p>
      <textarea onChange={onChange} value={question} rows={4} cols={50} placeholder={`Software Engineer`} maxLength={50}
required/>



      <button onClick={onClick}>Submit</button>

      <div class="container">
      {loading ? <p className='answer'>Networking AI is thinking, please wait 20-30 seconds ... </p> : <p className='answer'>{answer}</p>}
      </div>
      

     
      

      <Toolscards />





      <div className="terms-spacer"></div>

      </div>

      <Footer />

      </div>

    
     
  );
  
}

export default App;
