import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Email from './Email';
import Resume from './Resume';
import Coverletter from './Coverletter';
import Linkedinsummary from './Linkedinsummary';
import Linkedinheadline from './Linkedinheadline';
import Linkedinskills from './Linkedinskills';
import Recruitermessage from './Recruitermessage';
import Networkingmessage from './Networkingmessage';
import Linkedinpost from './Linkedinpost';
import Interviewprep from './Interviewprep';
import Askmeanything from './Askmeanything';
import Careerfinder from './Careerfinder';
import Elevatorpitch from './Elevatorpitch';
import Terms from './Terms';
import Privacy from './Privacy';
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/email" element={<Email />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/cover-letter" element={<Coverletter />} />
      <Route path="/linkedin-summary" element={<Linkedinsummary />} />
      <Route path="/linkedin-headline" element={<Linkedinheadline />} />
      <Route path="/linkedin-skills" element={<Linkedinskills />} />
      <Route path="/recruiter-message" element={<Recruitermessage />} />
      <Route path="/networking-message" element={<Networkingmessage />} />
      <Route path="/linkedin-post" element={<Linkedinpost />} />
      <Route path="/interview-prep" element={<Interviewprep />} />
      <Route path="/ask-me-anything" element={<Askmeanything />} />
      <Route path="/career-finder" element={<Careerfinder />} />
      <Route path="/elevator-pitch" element={<Elevatorpitch />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
